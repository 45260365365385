<template>
  <Toast ref="toast">
    <template #container="{ message, closeCallback }">
      <div :id="`${getIndex(message)}-toast`" class="p-toast__content">
        <p :id="`${getIndex(message)}-toast-message`" class="p-toast__summary">
          {{ message.summary }}
        </p>

        <KButton
          :id="`${getIndex(message)}-toast-close-button`"
          aria-label="Close"
          text
          @click="closeCallback"
        >
          <template #icon>
            <KIcon icon="fa-duotone fa-circle-xmark" />
          </template>
        </KButton>
      </div>
    </template>
  </Toast>
</template>

<script setup lang="ts">
import { ref } from "vue";
import Toast from "primevue/toast";
import { KButton, KIcon } from "@k2united/frontend_components";

interface Message {
  summary: string;
  id: number;
}

const toast = ref();

const getIndex = (message: Message) => {
  const messages: Message[] = toast.value.messages;
  return messages.findIndex((toastMessage) => toastMessage.id === message.id);
};
</script>

<style lang="scss">
.p-toast {
  white-space: pre-line;
  width: 25rem;
  word-break: break-word;
}

.p-toast__message {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.p-toast__container {
  background: $color-background-default;
  border: 1px solid $color-border-subtle;
  border-radius: 4px;
  box-shadow: 1px 4px 4px 0px rgba(0, 0, 0, 0.15);
  position: relative;
}

.p-toast__content {
  align-items: center;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  padding: 12px 8px 12px 12px;
}

.p-toast__summary {
  @include body;
  color: $color-text-default;
}

.p-toast-enter-from {
  opacity: 0;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
}

.p-toast-leave-from {
  max-height: 1000px;
}

.p-toast-leave-to {
  margin-bottom: 0;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

.p-toast-enter-active {
  -webkit-transition:
    transform 0.3s,
    opacity 0.3s;
  transition:
    transform 0.3s,
    opacity 0.3s;

  @media screen and (prefers-reduced-motion) {
    animation: none;
  }
}

.p-toast-leave-active {
  -webkit-transition:
    max-height 0.45s cubic-bezier(0, 1, 0, 1),
    opacity 0.3s,
    margin-bottom 0.3s;
  transition:
    max-height 0.45s cubic-bezier(0, 1, 0, 1),
    opacity 0.3s,
    margin-bottom 0.3s;

  @media screen and (prefers-reduced-motion) {
    transition: none;
  }
}
</style>
