import { VueQueryPlugin, QueryClient } from "@tanstack/vue-query";
import type { VueQueryPluginOptions } from "@tanstack/vue-query";
import type { App } from "vue";

// Documentation: https://tanstack.com/query/latest/docs/vue/guides/custom-client
export const queryClient = new QueryClient();

export default {
  install: (app: App) => {
    const vueQueryPluginOptions: VueQueryPluginOptions = {
      queryClient
    };

    app.use(VueQueryPlugin, vueQueryPluginOptions);
  }
};
