const AuthView = () => import("./AuthView.vue");
const ResetPasswordView = () => import("./ResetPasswordView.vue");
const SetPasswordView = () => import("./SetPasswordView.vue");
const ResetPassword = () => import("@/components/auth/ResetPassword.vue");
const ResetPasswordForm = () => import("@/components/auth/ResetPasswordForm.vue");
const TermsView = () => import("./TermsView.vue");
const UserShippingAddressView = () => import("./UserShippingAddressView.vue");
const CreateAccountView = () => import("./CreateAccountView.vue");
const ActivateAccountView = () => import("./ActivateAccountView.vue");
const VerifyEmailView = () => import("./VerifyEmailView.vue");
const VerifyEmailRequired = () => import("@/components/auth/VerifyEmailRequired.vue");

const authRoutes = [
  {
    path: "/auth",
    name: "Auth",
    component: AuthView,
    props: true,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPasswordView,
    meta: {
      requiresAuth: false
    },
    children: [
      {
        path: "",
        name: "ResetPasswordRequest",
        component: ResetPassword
      },
      {
        path: ":token",
        name: "ResetPasswordForm",
        component: ResetPasswordForm,
        props: true
      }
    ]
  },
  {
    path: "/set-password",
    name: "SetPassword",
    component: SetPasswordView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/terms",
    name: "Terms",
    component: TermsView,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/shipping-address",
    name: "UserShippingAddress",
    component: UserShippingAddressView,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/create-account",
    name: "CreateAccount",
    component: CreateAccountView,
    props: false,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/activate-account/:token",
    name: "ActivateAccount",
    component: ActivateAccountView,
    props: true,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/verify-email",
    name: "VerifyEmail",
    component: VerifyEmailView,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "",
        name: "VerifyEmailRequired",
        component: VerifyEmailRequired
      },
      {
        path: ":token",
        name: "VerifyEmailRequiredToken",
        component: VerifyEmailRequired,
        props: true
      }
    ]
  }
];

export default authRoutes;
