const SeatsView = () => import("./SeatsView.vue");

const seatsRoutes = [
  {
    path: "/seats",
    name: "Seats",
    component: SeatsView,
    meta: {
      requiresAuth: true
    }
  }
];

export default seatsRoutes;
