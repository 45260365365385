import { createRouter, createWebHistory } from "vue-router";
import { useUserStore, hasOrgPermission } from "@/stores/user";

import authRoutes from "@/views/auth/authRoutes";
import inviteRoutes from "@/views/invite/inviteRoutes";
import signInRoutes from "@/views/signIn/signInRoutes";
import signOutRoutes from "@/views/signOut/signOutRoutes";
import helpRoutes from "@/views/help/helpRoutes";
import homeRoutes from "@/views/home/homeRoutes";
import enrollmentRoutes from "@/views/enrollment/enrollmentRoutes";
import groupRoutes from "@/views/group/groupRoutes";
import catalogRoutes from "@/views/catalog/catalogRoutes";
import cartRoutes from "@/views/cart/cartRoutes";
import checkoutRoutes from "@/views/checkout/checkoutRoutes";
import orderRoutes from "@/views/orders/orderRoutes";
import notFoundRoutes from "@/views/notFound/notFoundRoutes";
import errorRoutes from "@/views/error/errorRoutes";
import coursesRoutes from "@/views/courses/coursesRoutes";
import seatsRoutes from "@/views/seats/seatsRoutes";
import gradebookRoutes from "@/views/gradebook/gradebookRoutes";
import internalRoutes from "@/views/internal/internalRoutes";

export const routes = [
  ...authRoutes,
  ...inviteRoutes,
  ...signInRoutes,
  ...signOutRoutes,
  ...helpRoutes,
  ...homeRoutes,
  ...enrollmentRoutes,
  ...groupRoutes,
  ...catalogRoutes,
  ...cartRoutes,
  ...checkoutRoutes,
  ...orderRoutes,
  ...gradebookRoutes,
  ...notFoundRoutes,
  ...errorRoutes,
  ...coursesRoutes,
  ...seatsRoutes,
  ...internalRoutes
];

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_APP_BASE_PATH),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth"
      };
    }
  }
});

router.onError((error, to) => {
  if (error.message.includes("Failed to fetch dynamically imported module")) {
    window.location.href = to.fullPath;
  }
});

router.beforeEach((to) => {
  const store = useUserStore();

  // If any url contains a referralCode, save it to a cookie
  if (to.query.referralCode) {
    const parts = location.hostname.split(".");
    const domain = parts.slice(-2).join(".");
    document.cookie =
      "referralCode=" + String(to.query.referralCode) + ";domain=." + domain + ";path=/";
    delete to.query.referralCode;
    router.push({ path: to.fullPath, query: to.query });
  }

  // Redirect to the sign page if trying to access protected page without authentication
  const isNotAuthenticated = to.name !== "SignIn" && to.meta.requiresAuth && !store.isAuthenticated;
  if (isNotAuthenticated) {
    // Redirect to create account page if trying to add to cart
    if (to.name === "Cart" && to.query.sku && to.query.quantity) {
      return { name: "CreateAccount", query: { redirect: to.fullPath.replace("?", "&") } };
    }

    return {
      path: "/signin",
      // Save the location we were at to come back later
      query: { redirect: to.fullPath.replace("?", "&") }
    };
  }

  // Redirect to activation page
  const isActivationRequired =
    to.name !== "ActivateAccount" &&
    store.isActivationRequired &&
    to.name !== "SignOut" &&
    to.name !== "SignIn" &&
    to.name !== "Error";
  if (isActivationRequired) {
    return {
      path: `activate-account/${store.currentUser?.activation_required}`,
      // Save the location we were at to come back later
      query: { redirect: to.fullPath.replace("?", "&") }
    };
  }

  // Redirect to terms and conditions page if authenticated but haven't accepted them yet
  const notAcceptedTerms =
    to.name !== "SignIn" &&
    to.meta.requiresAuth &&
    store.isAuthenticated &&
    !store.acceptedTerms &&
    to.name !== "Terms" &&
    to.name !== "SignOut";
  if (notAcceptedTerms) {
    // Set the redirect to the intended target unless they also need a shipping address.
    // In that case, don't redirect at all so that on submit, you get redirected there with the proper parameters.
    const query = store.user?.prompt_for_shipping_address ? undefined : { redirect: to.fullPath };
    return {
      path: "/terms",
      query: query
    };
  }

  // Redirect to set password page
  const isPasswordResetRequired = to.name !== "SetPassword" && store.isPasswordResetRequired;
  if (isPasswordResetRequired && !store.isActivationRequired && store.acceptedTerms) {
    return {
      path: "/set-password"
    };
  }

  // Redirect to an error page if ecommerce is disabled for students in the Org and they try to navigate to any ecommerce pages
  const blockEcommerce =
    store.user?.current_org?.security_settings?.disable_ecommerce &&
    store.isOnlyLearner &&
    (to.name === "Cart" ||
      to.name === "Catalogs" ||
      to.name === "Catalog" ||
      to.name === "CatalogRetail" ||
      to.name === "CatalogEducation" ||
      to.name === "Checkout" ||
      to.name === "CheckoutProcessing" ||
      to.name === "ContactSales" ||
      to.name === "PayNow" ||
      to.name === "AnonymousCheckout" ||
      to.name === "AnonymousCheckoutProcessing" ||
      to.name === "OrdersView" ||
      to.name === "OrderDetails" ||
      to.name === "OrderConfirmation");
  if (blockEcommerce) {
    return {
      path: "/ecommerce-disabled"
    };
  }

  // Redirect to an error page if trying to get to the licenses page without proper permissions
  const blockLicenses =
    to.name === "LicensesView" &&
    (!hasOrgPermission(store, "change_group") ||
      !store.user?.current_org?.has_descendent_organizations ||
      store.user?.current_org?.can_accept_license);
  if (blockLicenses) {
    return {
      name: "Error"
    };
  }

  // Check if there is a redirect query parameter and redirect to that path
  const isRedirect =
    store.isAuthenticated &&
    store.acceptedTerms &&
    !store.isPasswordResetRequired &&
    !store.isActivationRequired &&
    to.query.redirect &&
    to.name !== "UserShippingAddress" &&
    to.name !== "SetPassword" &&
    to.path !== to.query.redirect;
  if (isRedirect) {
    return {
      path: to.query.redirect
    };
  }
});

export default router;
