import { createApp } from "vue";
import { formatNumberDisplay } from "./utils/formatNumberDisplay";
import { formatDateDisplay } from "./utils/formatDateDisplay";
import { formatElapsedTimeDisplay } from "./utils/formatElapsedTimeDisplay";
import { formatWrittenDateDisplay } from "./utils/formatWrittenDateDisplay";
import { formatCurrencyUSD } from "./utils/formatCurrencyUSD";
import { formatPhoneNumber } from "./utils/formatPhoneNumber";
import { getCourseStatusSelector } from "./utils/getCourseStatusSelector";

import App from "./App.vue";

const app = createApp(App);

// Pinia
import pinia from "./plugins/pinia";
app.use(pinia);

// Router
import router from "./router";
app.use(router);

// Sentry
import sentry from "./plugins/sentry";
app.use(sentry);

// Google analytics
import gtag from "./plugins/gtag";
app.use(gtag);

// Vue Query
import vueQuery from "./plugins/vueQuery";
app.use(vueQuery);

// PrimeVue
import primeVue from "./plugins/primeVue";
app.use(primeVue);

// Toast
import toast from "./plugins/toast";
app.use(toast);

// Font Awesome
import fontAwesome from "./plugins/fontAwesome";
app.use(fontAwesome);

// Floating Vue
import floatingVue from "./plugins/floatingVue";
app.use(floatingVue);

// Yup
import yup from "./plugins/yup";
app.use(yup);

// Maska
import { vMaska } from "maska/vue";
app.directive("maska", vMaska);

// Unhead
import unhead from "./plugins/unhead";
app.use(unhead);

// MSW
const enableMocking = false;
if (import.meta.env.DEV && enableMocking) {
  import("./mocks/browser").then(({ worker }) => {
    worker.start();
  });
}

// Remove error in Sentry
window.addEventListener("vite:preloadError", (event) => {
  event.preventDefault();
});

// Filters
app.config.globalProperties.$filters = {
  formatNumberDisplay,
  formatDateDisplay,
  formatElapsedTimeDisplay,
  formatWrittenDateDisplay,
  formatCurrencyUSD,
  formatPhoneNumber,
  getCourseStatusSelector
};

app.mount("#app");
